import { createAction, props } from '@ngrx/store';

import { InvoiceChannel, InvoiceChannelOptions } from '../../models';

export const loadRequest = createAction('[Invoice Channels] Load Request');

export const loadSuccess = createAction(
  '[Invoice Channels] Load Success',
  props<{ items: InvoiceChannel[] }>(),
);

export const loadFailure = createAction(
  '[Invoice Channels] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Invoice Channels] Reset State');
