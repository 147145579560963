import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { InvoiceModule } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getPagination = (state: State): IPagination => state.pagination;

export const selectState = createFeatureSelector<State>('invoicesModule');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: string) =>
  createSelector(
    selectAllItems,
    (items: InvoiceModule[]) => items && items.find((p) => p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectPagination = createSelector(selectState, getPagination);
