import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InvoiceChannelsService {
  constructor(private http: HttpClient) {}

  load() {
    return this.http.get('general/invoice_channels');
  }

  loadOptions(invoiceChannelId: number) {
    return this.http.get(`general/invoice_channel/options/${invoiceChannelId}`);
  }
}
