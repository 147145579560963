import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { generateSearchQuery } from '../core/helpers/params-generator';
import { InvoiceModuleRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InvoicesModuleService {
  constructor(private http: HttpClient) {}

  load(searchData: { [key: string]: any }) {
    return this.http.get(
      `admin/invoicemodules?${generateSearchQuery(searchData)}`,
    );
  }

  create(data: Omit<InvoiceModuleRequest, 'id'>) {
    return this.http.post('admin/invoicemodule', data);
  }

  update(request: InvoiceModuleRequest) {
    return this.http.put(`admin/invoicemodule/${request.id}`, request);
  }

  delete(invoice_module_id: number) {
    return this.http.delete(`admin/invoicemodule/${invoice_module_id}`);
  }
}
