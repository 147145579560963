import { createAction, props } from '@ngrx/store';
import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { InvoiceModule, InvoiceModuleRequest } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Invoices Module] Load Request',
  props<{ request?: SearchParams }>(),
);

export const loadSuccess = createAction(
  '[Invoices Module] Load Success',
  props<{ items: InvoiceModule[]; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Invoices Module] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Invoices Module] Create Request',
  props<{
    request: Omit<InvoiceModuleRequest, 'id'>;
  }>(),
);

export const createSuccess = createAction(
  '[Invoices Module] Create Success',
  props<{ item: InvoiceModule }>(),
);

export const createFailure = createAction(
  '[Invoices Module] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Invoices Module] Update Request',
  props<{
    request: InvoiceModuleRequest;
  }>(),
);

export const updateSuccess = createAction(
  '[Invoices Module] Update Success',
  props<{ item: InvoiceModule }>(),
);

export const updateFailure = createAction(
  '[Invoices Module] Update Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Invoices Module] Delete Request',
  props<{
    itemId: number;
  }>(),
);

export const deleteSuccess = createAction(
  '[Invoices Module] Delete Success',
  props<{ itemId: number }>(),
);

export const deleteFailure = createAction(
  '[Invoices Module] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Invoices Module] Reset State');
