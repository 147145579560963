import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IPagination } from '../../core/models/api/pagination/pagination.model';

import { InvoiceModule } from '../../models';

export const featureAdapter: EntityAdapter<InvoiceModule> = createEntityAdapter<
  InvoiceModule
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<InvoiceModule> {
  isLoading?: boolean;
  pagination: IPagination;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  pagination: null,
});
